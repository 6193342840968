<template>
    <transition name="fade">
        <div v-if="modal" class="modal-overlay" />
    </transition>

    <!-- <button class="toggle-modal" @click="toggleModal">Toggle Modal</button> -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['modal']),
    },
    watch: {
        modal: function(val) {
            if (val) {
                document.querySelector('html').classList.add('no-scroll');
                document.querySelector('body').classList.add('no-scroll');
            } else {
                document.querySelector('html').classList.remove('no-scroll');
                document.querySelector('body').classList.remove('no-scroll');
            }
        }
    },
    // methods: {
    //     toggleModal() {
    //         this.$store.dispatch('modal', !this.modal);
    //     }
    // }
}
</script>

<style lang="scss" scoped>

.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: #00000090;
}

// .toggle-modal {
//     position: fixed;
//     left: 2rem;
//     bottom: 2rem;
// }

</style>