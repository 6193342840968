import axios from 'axios'
import router from '@/router'
import store from '@/store'
import Notify from 'quasar/src/plugins/Notify.js';;

function handleError(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
            if (error.config.url == "me") {
                localStorage.removeItem('jwt_token');
                localStorage.removeItem('token_expires');
                store.dispatch('user', null);
                router.push('/');
                store.dispatch('loading', false);
                store.dispatch('modal', false);
            } else if (error.config.url == "login") {
                Notify.create({
                    position: 'bottom-left', 
                    message: error.response.data.message, 
                    color: 'negative'
                });
                store.dispatch('loading', false);
                store.dispatch('modal', false);
            } else if (!error.config.url == "refresh-token") {
                showUnauthorizedPage();
                store.dispatch('loading', false);
                store.dispatch('modal', false);
            }
        }

        if (error.response.status === 404) {
            showNotFoundPage();
            store.dispatch('loading', false);
            store.dispatch('modal', false);
        }

        if (error.response.status === 500) {
            showServerErrorPage();
            store.dispatch('loading', false);
            store.dispatch('modal', false);
        }

        if (error.response.status === 422) {
            for (let issue in error.response.data) {
                Notify.create({
                    position: 'bottom-left', 
                    message: `${issue}: ${error.response.data[issue]}`, 
                    color: 'negative'
                });
            }
            store.dispatch('loading', false);
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
}

function promiseGetRequest(url) {
    return new Promise((resolve, reject) => {
        if(checkIfTokenIsValid() === false) reject();
        axios.get(url)
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                handleError(error);
                reject();
            });
    });
}

function promisePostRequest(url, data) {
    return new Promise((resolve, reject) => {
        if(checkIfTokenIsValid() === false) reject();
        axios.post(url, data)
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                handleError(error);
                reject();
            });
    });
}

function promisePutRequest(url, data) {
    return new Promise((resolve, reject) => {
        if(checkIfTokenIsValid() === false) reject();
        axios.put(url, data)
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                handleError(error);
                reject();
            });
    });
}

function promiseDeleteRequest(url) {
    return new Promise((resolve, reject) => {
        if(checkIfTokenIsValid() === false) reject();
        axios.delete(url)
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                handleError(error);
                reject();
            });
    });
}

function showNotFoundPage() {
    router.push('/404')
}

function showServerErrorPage() {
    router.push('/500')
}

function showUnauthorizedPage() {
    router.push('/401')
}

function checkIfTokenIsValid() {
    if (localStorage.getItem('jwt_token')) {
        if (localStorage.getItem('token_expires') < new Date().getTime()) {
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('token_expires');
            store.dispatch('user', null);
            Notify.create({
                position: 'bottom-left', 
                message: 'Your Session Expired: Please login again', 
                color: 'negative'
            });
            router.push('/login');
            return false;
        } else {
            return true;
        }
    } else {
        return undefined;
    }
}

export { 
        handleError,
        promiseGetRequest,
        promisePostRequest,
        promisePutRequest,
        promiseDeleteRequest 
};