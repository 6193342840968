const resource = {
    en: {
        home: 'Home',
        learning_modules: 'Learning Modules',
        planungsmatrix: 'Planungsmatrix',
        register: 'Register',
        login: 'Login',
        logout: 'Logout',
        account: 'Account',
        dashboard: 'Dashboard',
    },
    de: {
        home: 'Home',
        learning_modules: 'Lernmodule',
        planungsmatrix: 'Planungsmatrix',
        register: 'Registrieren',
        login: 'Anmelden',
        logout: 'Abmelden',
        account: 'Profil',
        dashboard: 'Dashboard',
    }
}

export default resource;