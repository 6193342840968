<template>
    <main>
        <div class="container">
            <slot/>
        </div>
    </main>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

.container {
    margin-block-start: 7.5rem;
    margin-block-end: 10rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
}

</style>