const resource = {
    en: {
        connection_lost: 'Lost Connection to the Internet',
        connection_reestablished: 'Connection to Internet reestablished',
        connection_lost_banner: 'You have lost connection to the internet. This app is offline.'
    },
    de: {
        connection_lost: 'Internetverbindung verloren',
        connection_reestablished: 'Internetverbindung wiederhergestellt',
        connection_lost_banner: 'Sie haben die Verbindung zum Internet verloren. Die Anwendung ist jetzt offline.'
    }
}

export default resource;