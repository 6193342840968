const resource = {
    en: {
        legal_disclaimer: 'Legal Disclaimer',
        legal_information: 'Legal Information',
        privacy_policy: 'Privacy Policy',
        cookie_information: 'Cookie Information',
    },
    de: {
        legal_disclaimer: 'Haftungsausschluss',
        legal_information: 'Impressum',
        privacy_policy: 'Datenschutz',
        cookie_information: 'Cookie-Richtlinien',
    }
}

export default resource;