<template>
    <div v-if="mobile_menu" class="mobile-menu no-desktop">
        <div class="user-menu">
            <router-link to="/register" v-if="!user" v-html="resource[lang].register" />
            <router-link to="/login" v-if="!user" v-html="resource[lang].login" />
            <router-link to="/dashboard/my-matrices" v-if="user" v-html="resource[lang].dashboard" />
            <router-link to="/account/account-properties" v-if="user" v-html="resource[lang].account" />
            <button v-if="user" @click="logout" v-html="resource[lang].logout" />
            <TheLanguageSelector />
            <!-- <TheSearchComponent /> -->
        </div>
        <div class="main-menu">
            <router-link to="/" v-html="resource[lang].home" />
            <router-link to="/learning-modules" v-html="resource[lang].learning_modules" />
            <router-link to="/planungsmatrix" v-html="resource[lang].planungsmatrix" />
            <!-- <p v-html="resource[lang].home" />
            <p v-html="resource[lang].learning_modules" />
            <p v-html="resource[lang].planungsmatrix" /> -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/the-header-resource'

import TheLanguageSelector from '@/components/TheLanguageSelector'

import { promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        TheLanguageSelector,
        // TheSearchComponent
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    computed: {
        ...mapGetters(['mobile_menu', 'user'])
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    methods: {
        // Request
        logoutRequest() {
            return promisePostRequest('logout');
        },

        // Action
        async logout() {
            this.$store.dispatch('loading', true);
            await this.logoutRequest();
            this.$store.dispatch('loading', false);

            this.$store.dispatch('user', null);
            localStorage.removeItem('jwt_token');

            this.quasar.notify({
                position: 'bottom-left', 
                message: 'You have been logged out', 
                color: 'positive'
            });
            this.$emit('logout');
            this.$router.push('/');
        }
    },
    watch: {
        mobile_menu: function(val) {
            if (val) {
                document.querySelector('html').classList.add('no-scroll');
                document.querySelector('body').classList.add('no-scroll');
            } else {
                document.querySelector('html').classList.remove('no-scroll');
                document.querySelector('body').classList.remove('no-scroll');
            }
        },
        $route: function() {
            this.$store.dispatch('mobile_menu', false);
        }
    },
    emits: ['logout']
}
</script>

<style lang="scss" scoped>

.mobile-menu {
    position: fixed;
    top: 5rem;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    background-color: var(--light_grey);

    padding: 1.5rem;
    padding-top: 10rem;

    .user-menu {
        position: absolute;

        right: 1.5rem;
        bottom: 5rem;

        text-align: right;

        & > * {
            display: block;
            margin-left: auto;

            background-color: var(--light_grey);
            border: none;

            color: var(--blak);

            font-family: var(--standard-font);
            font-size: 2rem;
            font-weight: 400;

            margin-block-start: 0.25rem;
            margin-block-end: 0.25rem;
        }

        .router-link-active {
            color: var(--accent);
        }
        
    }

    .main-menu {

        & > * {
            color: var(--blak);
            display: block;
            font-family: var(--standard-font);
            font-size: 2rem;
            font-weight: 500;
            margin-block-start: 0.5rem;
            margin-block-end: 0.5rem;
        }

        .router-link-active {
            color: var(--accent);
        }
    }
    
    
}

@media (max-width: 40rem) {
    .mobile-menu {
        padding-top: 3rem;
    }

    .user-menu {
        bottom: 1rem !important;
    }
}

</style>