<template>
    <select class="lang-select" v-model="lang" @change="handleLanguageChange">
        <option value="en">EN</option>
        <option value="de">DE</option>
    </select>
</template>

<script>
export default {
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
        }
    },
    methods: {
        handleLanguageChange() {
            localStorage.setItem('lang', this.lang);
            window.location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>

.lang-select {
    cursor: pointer;
    background: none;
    border: none;
    color: var(--dark_grey);
    font-family: var(--standard-font);
    font-size: 1rem;
    font-weight: 600;
}

.lang-select:hover,
.lang-select:focus-visible {
    color: var(--blak);
    outline: none;
}
</style>