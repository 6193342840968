<template>
    <header>
        <div class="container flex">
            <div class="logo">
                <router-link to="/">Planungsmatrix</router-link>
                <!-- <router-link to="/dashboard/my-matrices">Planungsmatrix</router-link> -->
            </div>
            <!-- Desktop -->
            <div class="menu no-tablet no-mobile">
                <div class="user-menu">
                    <router-link to="/register" v-if="!user" v-html="resource[lang].register" />
                    <router-link to="/login" v-if="!user" v-html="resource[lang].login" />
                    <router-link to="/dashboard/my-matrices" v-if="user" v-html="resource[lang].dashboard" />
                    <router-link to="/account/account-properties" v-if="user" v-html="resource[lang].account" />
                    <button v-if="user" @click="logout" v-html="resource[lang].logout" />
                    <TheLanguageSelector />
                    <!-- <TheSearchComponent /> -->
                </div>
                <div class="main-menu">
                    <router-link to="/" v-html="resource[lang].home" />
                    <router-link to="/learning-modules" v-html="resource[lang].learning_modules" />
                    <router-link to="/planungsmatrix" v-html="resource[lang].planungsmatrix" />
                    <!-- <p v-html="resource[lang].home" />
                    <p v-html="resource[lang].learning_modules" />
                    <p v-html="resource[lang].planungsmatrix" /> -->
                </div>
            </div>
            <!-- Tablet -->
            <div class="mobile-menu no-desktop">
                <q-icon name="fas fa-bars" size="xl" color="primary" @click="$store.dispatch('mobile_menu', !mobile_menu)" />
            </div>
        </div>
    </header>
</template>

<script>
import { useQuasar } from 'quasar'
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/the-header-resource'

import TheLanguageSelector from '@/components/TheLanguageSelector'
// import TheSearchComponent from '@/components/TheSearchComponent'

import { promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        TheLanguageSelector,
        // TheSearchComponent
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    computed: {
        ...mapGetters(['mobile_menu', 'user']),
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    methods: {
        // Request
        logoutRequest() {
            return promisePostRequest('logout');
        },

        // Action
        async logout() {
            this.$store.dispatch('loading', true);
            await this.logoutRequest();
            this.$store.dispatch('loading', false);

            this.$store.dispatch('user', null);
            localStorage.removeItem('jwt_token');

            this.quasar.notify({
                position: 'bottom-left', 
                message: 'You have been logged out', 
                color: 'positive'
            });
            this.$emit('logout');
            this.$router.push('/');
        }
    },
    emits: ['logout']
}
</script>

<style lang="scss" scoped>

header {
    background-color: var(--light_grey);
}

.container {
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
    padding-block-start: 2rem;
    padding-block-end: 3rem;
}

.flex {
    justify-content: space-between;
}

.logo {
    align-self: flex-end;
    
    a {
        color: var(--accent);
        font-family: var(--standard-font);
        font-size: 3.125rem;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
    }
}

.menu {
    .main-menu {
        text-align: right;

        p {
            display: inline;
        }

        p,
        a {
            color: var(--text);
            font-family: var(--standard-font);
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.2;
        }

        p + p,
        a + a {
            margin-left: 3rem;
        }

        p:hover, p:focus-visible,
        a:hover,
        a:focus-visible {
            color: var(--accent);
            outline: none;
        }

        a:active {
            color: var(--accent);
        }

        .router-link-active {
            color: var(--accent);
        }
    }

    .user-menu {
        text-align: right;
        margin-bottom: 2.5rem;

        a, button {
            color: var(--dark_grey);
            font-family: var(--standard-font);
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.2;
        }

        a:hover, button:hover,
        a:focus-visible, button:focus-visible {
            color: var(--blak);
            outline: none;
        }

        a:active {
            color: var(--blak);
        }

        button {
            cursor: pointer;
            background: none;
            border: none;
        }

        .router-link-active {
            color: var(--blak);
        }

        & > * + * {
            margin-left: 2.5rem;
        }
    }
}

@media (max-width: 40rem) {
    .logo a {
        font-size: 1.5rem;
    }
}

</style>