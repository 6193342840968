import axios from 'axios';

// DEV
//axios.defaults.baseURL = 'http://localhost:8000/';

// PROD
axios.defaults.baseURL = 'https://matrix.geschichtsdidaktik.eu/api/';
// axios.defaults.baseURL = 'https://planungsmatrix.univie.ac.at/api/';

//axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';