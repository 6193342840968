<template>
    
    <transition name="fade">
        <div class="loading-overlay" v-show="loading">
            <q-spinner-oval
                class="loading-symbol"
                color="accent"
                size="2rem"
            />
        </div> 
    </transition>  

    <!-- <button class="toggle-loading" @click="toggleLoading">Toogle Loading</button> -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['loading']),
    },
    watch: {
        loading: function(val) {
            if (val) {
                document.querySelector('html').classList.add('no-scroll');
                document.querySelector('body').classList.add('no-scroll');
            } else {
                document.querySelector('html').classList.remove('no-scroll');
                document.querySelector('body').classList.remove('no-scroll');
            }
        }
    },
    // methods: {
    //     toggleLoading() {
    //         this.$store.dispatch('loading', !this.loading);
    //     }
    // }
}
</script>

<style lang="scss" scoped>

.loading-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: #ffffff90;
}

.loading-symbol {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}

// .toggle-loading {
//     position: fixed;
//     left: 2rem;
//     bottom: 2rem;
// }

</style>