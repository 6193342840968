<template>
  <q-banner v-if="!on_line" nline-actions class="text-white bg-red" v-html="resource[lang].connection_lost_banner" />
  <TheHeader @logout="handleLogout" />
  <ThePageContent>
      <router-view :key="$route.fullPath" v-slot="{ Component }">
        <transition name="fade">
            <component :is="Component" />
        </transition>
      </router-view>
  </ThePageContent>
  <TheFooter />
  <TheLoadingIndicator />
  <TheModalOverlay />
  <TheMobileMenu />
</template>

<script>
import resource from '@/assets/resources/app-resource'

import axios from 'axios'
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'
import TheMobileMenu from '@/components/TheMobileMenu.vue'
import TheModalOverlay from '@/components/TheModalOverlay.vue'
import ThePageContent from '@/components/ThePageContent.vue'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
  components: { 
    TheFooter,
    TheHeader,
    TheLoadingIndicator,
    TheMobileMenu,
    TheModalOverlay,
    ThePageContent,
  },
  data() {
    const lang = localStorage.getItem('lang') || 'en';

    return {
      lang: lang,
      refresh_token_timer: null,

      on_line: navigator.onLine,

      resource
    }
  },
  computed: {
    ...mapGetters(['user', 'loading']),
  },
  setup() {
      const quasar = useQuasar();
      
      return { 
          quasar
      }
  },
  created() {
    this.loadUserData();
    this.setTimer();
  },
  mounted() {
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeUnmount() {
      window.removeEventListener('online', this.updateOnlineStatus);
      window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    // Requests
    getMeRequest() {
      return promiseGetRequest('me');
    },
    refreshTokenRequest() {
      return promiseGetRequest('refresh-token')
    },

    // Actions
    async loadUserData() {
      if (localStorage.getItem('jwt_token')) {
        if (localStorage.getItem('token_expires') > new Date().getTime()) {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');
          
          this.$store.dispatch('loading', true);
          const response = await this.getMeRequest();
          this.$store.dispatch('loading', false);

          if (response.status === 'success' || response.status === 200) {
            this.$store.dispatch('user', response.data);
          } else {
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('token_expires');
          }
        } else {
          localStorage.removeItem('jwt_token');
          localStorage.removeItem('token_expires');
        }
      }
    },
    async refreshToken() {
      const response = await this.refreshTokenRequest().catch(() => {
        clearInterval(this.refresh_token_timer);
        this.refresh_token_timer = null;
      });

      if (response && response.data && response.data.access_token) {
        localStorage.setItem('jwt_token', response.data.access_token);
        const expire_time = new Date().getTime() + (response.data.expires_in * 1000);
        localStorage.setItem('token_expires', expire_time);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');
      }
    },
    handleLogout() {
      clearInterval(this.refresh_token_timer);
      this.refresh_token_timer = null;
    },

    // Functions
    setTimer() {
      if (localStorage.getItem('jwt_token')) {
        if (localStorage.getItem('token_expires') > new Date().getTime()) {
          this.refresh_token_timer = setInterval(this.refreshToken, 15 * 60 * 1000);
        }
      }
    },

    // online checker
    updateOnlineStatus(e) {
      const { type } = e;
      this.on_line = type === 'online';
    }
  },
  watch: {
    user: function(val) {
      if (val) {
        this.setTimer();
      }
    },
    on_line: function(val) {
      if (val) {
        this.$store.dispatch('loading', false);
        this.quasar.notify({
          position: 'bottom-left', 
          message: this.resource[this.lang].connection_reestablished,
          color: 'positive'
        });
      } else {
        this.$store.dispatch('loading', true);
        this.quasar.notify({
          position: 'bottom-left', 
          message: this.resource[this.lang].connection_lost,
          color: 'negative'
        });
      }
    }
  }
}
</script>
