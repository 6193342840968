import { createStore } from 'vuex'

export default createStore({
  state: {
    loading: false,
    mobile_menu: false,
    modal: false,
    user: null,
  },
  getters: {
    loading: state => {
      return state.loading;
    },
    mobile_menu: state => {
      return state.mobile_menu;
    },
    modal: state => {
      return state.modal;
    },
    user: state => {
      return state.user;
    },
  },
  mutations: {
    loading: (state, loading) => {
      state.loading = loading;
    },
    mobile_menu: (state, mobile_menu) => {
      state.mobile_menu = mobile_menu;
    },
    modal: (state, modal) => {
      state.modal = modal;
    },
    user: (state, user) => {
      state.user = user;
    }
  },
  actions: {
    loading: (context, loading) => {
      context.commit('loading', loading);
    },
    mobile_menu: (context, mobile_menu) => {
      context.commit('mobile_menu', mobile_menu);
    },
    modal: (context, modal) => {
      context.commit('modal', modal);
    },
    user: (context, user) => {
      context.commit('user', user);
    }
  },
})
