import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // Main Menu Routes
  {
    path: '/',
    name: 'Home Planungsmatrix',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/learning-modules',
    name: 'Learning Modules',
    component: () => import('@/views/LearningModules.vue'),
  },
  {
    path: '/planungsmatrix',
    name: 'Planungsmatrix',
    component: () => import('@/views/Planungsmatrix.vue'),
  },

  // Authentication Routes
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
  },
  {
    path: '/forgot-password-confirmation',
    name: 'Forgot Password Confirmation',
    component: () => import('@/views/authentication/ForgotPasswordConfirmation.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/authentication/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/authentication/Register.vue'),
  },
  {
    path: '/register-with-teem',
    name: 'Register with TEEM',
    component: () => import('@/views/authentication/RegisterWithTeem.vue'),
  },
  {
    path: '/registration-confirmation',
    name: 'Registration Confirmation',
    component: () => import('@/views/authentication/RegistrationConfirmation.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: () => import('@/views/authentication/ResetPassword.vue'),
  },
  {
    path: '/reset-password-confirmation',
    name: 'Reset Password Confirmation',
    component: () => import('@/views/authentication/ResetPasswordConfirmation.vue'),
  },
  {
    path: '/user-verification/:token',
    name: 'User Verification',
    component: () => import('@/views/authentication/UserVerification.vue'),
  },

  // Mandatory Routes
  {
    path: '/cookies-information',
    name: 'Cookies Information',
    component: () => import('@/views/mandatory/CookiesInformation.vue'),
  },
  {
    path: '/legal-disclaimer',
    name: 'Legal Disclaimer',
    component: () => import('@/views/mandatory/LegalDisclaimer.vue'),
  },
  {
    path: '/legal-information',
    name: 'Legal Information',
    component: () => import('@/views/mandatory/LegalInformation.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('@/views/mandatory/PrivacyPolicy.vue'),
  },
  {
    path: '/404',
    name: 'Not Found',
    component: () => import('@/views/mandatory/NotFound.vue'),
  },
  {
    path: '/500',
    name: 'Server Error',
    component: () => import('@/views/mandatory/ServerError.vue'),
  },
  {
    path: '/401',
    name: 'Unauthorized',
    component: () => import('@/views/mandatory/Unauthorized.vue'),
  },

  // Account Routes
  {
    path: '/account',
    component: () => import('@/views/account/Account.vue'),
    children: [
      {
        path: 'account-properties',
        name: 'Account Properties',
        component: () => import('@/views/account/AccountProperties.vue'),
      },
      {
        path: 'change-password',
        name: 'Change Password',
        component: () => import('@/views/account/ChangePassword.vue'),
      },
      {
        path: 'delete-account',
        name: 'Delete Account',
        component: () => import('@/views/account/DeleteAccount.vue'),
      },
      {
        path: 'learning-modules',
        name: 'Project Learning Modules',
        component: () => import('@/views/account/ProjectLearningModules.vue'),
      },
      {
        path: 'files',
        name: 'Project Files',
        component: () => import('@/views/account/ProjectFiles.vue'),
      },
      {
        path: 'users',
        name: 'Project Users',
        component: () => import('@/views/account/ProjectUsers.vue'),
      },
      {
        path: 'user-roles',
        name: 'Project User Roles',
        component: () => import('@/views/account/ProjectUserRoles.vue'),
      }
    ]
  },

  // Dashboard Routes
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    children: [
      {
        path: 'my-matrices',
        name: 'My Matrices',
        component: () => import('@/views/dashboard/MyMatrices.vue'),
      },
      {
        path: 'shared-matrices',
        name: 'Shared Matrices',
        component: () => import('@/views/dashboard/SharedMatrices.vue'),
      },
      {
        path: 'learning-class/:id?',
        name: 'Learning Class',
        component: () => import('@/views/dashboard/LearningClass.vue'),
      }
    ]
  },

  // Matrix Routes
  {
    path: '/matrix/:id?',
    name: 'Matrix',
    component: () => import('@/views/Matrix.vue'),
  },

  // Learning Module Routes
  {
    path: '/learning-module/:id',
    name: 'Learning Module',
    component: () => import('@/views/LearningModule.vue'),
  },
  
  // Other Routes
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/mandatory/NotFound.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
