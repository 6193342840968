import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import '@/assets/scss/reset.scss'
import '@/assets/scss/styles.scss'
import '@/assets/scss/transitions.scss'
import '@/assets/scss/utilities.scss'
import '@/axios/index';
import titleMixin from '@/assets/js/title-mixin'

// const lang = localStorage.getItem('lang') || 'en';
// axios.defaults.headers['Accept-Language'] = lang;

const app = createApp(App)
app.use(Quasar, quasarUserOptions)
app.use(store)
app.use(router)
app.mixin(titleMixin)
app.mount('#app')

app.provide('$dateOptions', {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
});

app.provide('$languageOptions', ['de', 'en']);
