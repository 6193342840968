<template>
    <footer>
        <div class="container footer-menu">
            <router-link to="/legal-information" v-html="resource[lang].legal_information" />
            <!-- <router-link to="/legal-disclaimer" v-html="resource[lang].legal_disclaimer" /> -->
            <router-link to="/privacy-policy" v-html="resource[lang].privacy_policy" />
            <!-- <router-link to="/cookies-information" v-html="resource[lang].cookie_information" /> -->
        </div>
    </footer>
</template>

<script>
import resource from '@/assets/resources/the-footer-resource'

export default {
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
}
</script>

<style lang="scss" scoped>

footer {
    background-color: var(--light_grey);

    /* Make Footer stick to bottom of the page */
    margin-top: auto;
}

.footer-menu {
    text-align: center;
    padding-block-start: 3rem;
    padding-block-end: 2rem;

    a {
        color: var(--blak);
        font-family: var(--standard-font);
        font-size: 1.25rem;
        font-weight: 600;

        padding-inline-start: 1.5rem;
        padding-inline-end: 1.5rem;
    }

    a:hover,
    a:focus-visible {
        color: var(--accent);
        outline: none;
    }

    .router-link-active {
        color: var(--accent);
    }
}

</style>